import React, { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  useTexture,
  Loader,
  Environment,
  useFBX,
  useAnimations,
  OrthographicCamera,
} from "@react-three/drei";
import { MeshStandardMaterial } from "three/src/materials/MeshStandardMaterial";

import { LinearEncoding, sRGBEncoding } from "three/src/constants";
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from "three";
import ReactAudioPlayer from "react-audio-player";

import createAnimation from "./converter";
import blinkData from "./blendDataBlink.json";

import * as THREE from "three";
import axios from "axios";

import queryString from "query-string";

const _ = require("lodash");

const host = "https://talkingapi.vplaysmart.com";

let SpeechRecognition =
  window.webkitSpeechRecognition || window.SpeechRecognition;
let SpeechGrammarList =
  window.SpeechGrammarList || window.webkitSpeechGrammarList;
let correct_count = 0;
let wrong_count = 0;

function Avatar({
  avatar_url,
  speak,
  setSpeak,
  text,
  setAudioSource,
  playing,
}) {
  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // teethSpecularTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture([
    "/images/body.webp",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/images/body_specular.webp",
    "/images/body_roughness.webp",
    "/images/body_normal.webp",
    "/images/teeth_normal.webp",
    // "/images/teeth_specular.webp",
    "/images/h_color.webp",
    "/images/tshirt_diffuse.webp",
    "/images/tshirt_normal.webp",
    "/images/tshirt_roughness.webp",
    "/images/h_alpha.webp",
    "/images/h_normal.webp",
    "/images/h_roughness.webp",
  ]);

  _.each(
    [
      bodyTexture,
      eyesTexture,
      teethTexture,
      teethNormalTexture,
      bodySpecularTexture,
      bodyRoughnessTexture,
      bodyNormalTexture,
      tshirtDiffuseTexture,
      tshirtNormalTexture,
      tshirtRoughnessTexture,
      hairAlphaTexture,
      hairNormalTexture,
      hairRoughnessTexture,
    ],
    (t) => {
      t.encoding = sRGBEncoding;
      t.flipY = false;
    }
  );

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Wolf3D_Avatar")) {
        //node.castShadow = true;
        //node.receiveShadow = true;

        //node.material = new MeshPhysicalMaterial();
        //node.material.map = bodyTexture;
        // node.material.shininess = 60;
        //node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        //node.material.roughnessMap = bodyRoughnessTexture;
        //node.material.normalMap = bodyNormalTexture;
        //node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        //node.material.envMapIntensity = 0.8;
        // node.material.visible = false;
      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {
        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.3;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);

        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }
  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (speak === false) return;

    makeSpeech(text)
      .then((response) => {
        let { blendData, filename } = response.data;

        let newClips = [
          createAnimation(
            blendData,
            morphTargetDictionaryBody,
            "Wolf3D_Avatar"
          ),
          //createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')
        ];

        filename = host + filename;

        setClips(newClips);
        setAudioSource(filename);
      })
      .catch((err) => {
        console.error(err);
        setSpeak(false);
      });
  }, [speak]);

  let idleFbx = useFBX("/idle15.fbx");
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, (track) => {
    return (
      track.name.includes("Head") ||
      track.name.includes("Neck") ||
      track.name.includes("Spine2")
    );
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, (track) => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;
  });

  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(
      blinkData,
      morphTargetDictionaryBody,
      "HG_Body"
    );
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();
  }, []);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false) return;

    _.each(clips, (clip) => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
    });
  }, [playing]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

function makeSpeech(text) {
  return axios.post(host + "/talk", { text });
}

const STYLES = {
  area: {
    position: "absolute",
    top: "7rem",
    right: "10px",
    width: "40%",
    zIndex: 500,
  },
  text: {
    margin: "0px",
    width: "300px",
    padding: "5px",
    background: "none",
    color: "#ffffff",
    fontSize: "1.2em",
    border: "none",
  },
  speak: {
    padding: "10px",
    marginTop: "5px",
    display: "block",
    color: "#FFFFFF",
    background: "#222222",
    border: "None",
  },
  area2: { position: "absolute", top: "5px", right: "15px", zIndex: 500 },
  label: { color: "#777777", fontSize: "0.8em" },
};

function App() {
  const audioPlayer = useRef();
  const timerRef = useRef(null);
  const recognitionRef = useRef(null);

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState("Hey I'm vOne. How may i help you?");
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [listen, setListen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [timerActive, setTimerActive] = useState(false);
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);

  const [userSpokenWord, setUserSpokenWord] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [antonym, setAntonym] = useState(null);
  const [synonym, setSynonym] = useState(null);
  const [antButton, setAntButton] = useState(true);
  const [synButton, setSynButton] = useState(true);
  const [showAntonym, setShowAntonym] = useState(false);
  const [showSynonym, setShowSynonym] = useState(false);

  const [queryParams, setQueryParams] = useState({});

  // Timer functionality
  const startTimer = () => {
    setTimeLeft(15);
    setTimerActive(true);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          handleTimeUp();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      setTimerActive(false);
    }
  };

  const handleTimeUp = () => {
    stopTimer();
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecognitionActive(false);
    setWrongCount((prev) => prev + 1);
    setSpeak(true);
    setText("Time's up! Click 'Next Question' to continue.");
    setListen(false);

    const testBtn = document.getElementById("testBtn");
    if (testBtn) {
      testBtn.disabled = false;
      testBtn.textContent = "Next Question";
    }
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    setQueryParams(params);
  }, []);

  // Player event handlers
  const playerEnded = (e) => {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);

    // Only start timer and recognition for initial word prompt
    if (listen && !isRecognitionActive && text.includes("Can you spell")) {
      startTimer();
      setMikeListen();
    }
  };

  const playerReady = (e) => {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  };

  function handleClick() {
    setSpeak(true);
    setText("Hey I'm vOne. How may i help you?");
    var eone = document.getElementById("evone");
    eone.disabled = true;
    setListen(false);
  }

  function randomPhrase(phrases) {
    var number = Math.floor(Math.random() * phrases.length);
    return number;
  }

  async function getRandomWordFromUrl(grade, sub) {
    try {
      const response = await axios.post(
        "https://languageassistant.vplaysmart.com/api/random-word",
        {
          grade: grade,
          subject: sub,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async function getAntonymsFromUrl(grade, sub, word) {
    console.log("WORD:::ANTONYM:::", word);
    try {
      const response = await axios.post(
        "https://languageassistant.vplaysmart.com/api/antonyms",
        {
          grade: grade,
          subject: sub,
          word: word,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async function getSynonymsFromUrl(grade, sub, word) {
    console.log("WORD:::Synonym:::", word);
    try {
      const response = await axios.post(
        "https://languageassistant.vplaysmart.com/api/synonyms",
        {
          grade: grade,
          subject: sub,
          word: word,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async function handleMikeListening() {
    stopTimer();
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecognitionActive(false);
    setAntButton(true);
    setSynButton(true);
    setCorrectAnswer(null);
    setUserSpokenWord(null);
    setShowAntonym(false);
    setShowSynonym(false);

    // Disable the button while processing
    const testBtn = document.getElementById("testBtn");
    if (testBtn) {
      testBtn.disabled = true;
      testBtn.textContent = "vOne Processing...";
    }

    // Get random word
    let grade = "Grade-1";
    let sub = "English";
    if (queryParams.level == "wordgame1") {
      grade = "Grade-1";
    } else if (queryParams.level == "wordgame2") {
      grade = "Grade-2";
    } else if (queryParams.level == "wordgame3") {
      grade = "Grade-3";
    }

    try {
      const response = await getRandomWordFromUrl(grade, sub);
      let phrase = response.word;
      console.log("PHRASE::::", phrase);

      var phraseTxt = "Can you spell " + phrase.toLowerCase();
      setSpeak(true);
      setText(phraseTxt);
      setPhrase(phrase);
      setListen(true);
    } catch (error) {
      console.error("Error getting random word:", error);
      setSpeak(true);
      setText("Sorry, there was an error. Please try again.");
      if (testBtn) {
        testBtn.disabled = false;
        testBtn.textContent = "Try Again";
      }
    }
  }

  async function handleAntonym() {
    let grade = "Grade-1";
    let sub = "English";
    if (queryParams.level == "wordgame1") {
      grade = "Grade-1";
    } else if (queryParams.level == "wordgame2") {
      grade = "Grade-2";
    } else if (queryParams.level == "wordgame3") {
      grade = "Grade-3";
    } else {
      grade = "Grade-1";
    }
    const response = await getAntonymsFromUrl(grade, sub, phrase);
    let antonyms = (response?.antonyms).toString();
    setAntonym(antonyms);
    console.log("anto_synonyms::::", antonyms);
    setSpeak(true);
    setText(antonyms);
    setListen(false);
    setAntButton(true);
    setShowAntonym(true); // Show the antonym div
  }

  async function handleSynonym() {
    let grade = "Grade-1";
    let sub = "English";
    if (queryParams.level == "wordgame1") {
      grade = "Grade-1";
    } else if (queryParams.level == "wordgame2") {
      grade = "Grade-2";
    } else if (queryParams.level == "wordgame3") {
      grade = "Grade-3";
    } else {
      grade = "Grade-1";
    }
    const response = await getSynonymsFromUrl(grade, sub, phrase);
    let synonyms = (response?.synonyms).toString();
    setSynonym(synonyms);
    console.log("anto_synonyms::::", synonyms);
    setSpeak(true);
    setText(synonyms);
    setListen(false);
    setSynButton(true);
    setShowSynonym(true);
  }

  function setMikeListen() {
    if (isRecognitionActive) return;

    const recognition = new (window.webkitSpeechRecognition ||
      window.SpeechRecognition)();
    recognitionRef.current = recognition;

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
    recognition.lang = "en-US";

    let speechRecognitionList = new (window.SpeechGrammarList ||
      window.webkitSpeechGrammarList)();
    let grammar =
      "#JSGF V1.0; grammar phrase; public <phrase> = " + phrase + ";";
    speechRecognitionList.addFromString(grammar, 1);
    recognition.grammars = speechRecognitionList;

    recognition.onstart = () => {
      setIsRecognitionActive(true);
      console.log("Speech recognition started");
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript
        .toLowerCase()
        .replace(/ /g, "")
        .replace("canyouspell", "");

      console.log("Speech result:", speechResult);

      if (speechResult.includes(phrase.toLowerCase())) {
        stopTimer();
        recognition.stop();
        setUserSpokenWord(speechResult);
        setCorrectAnswer(phrase);
        setSpeak(true);
        setText("Correct! Click 'Next Question' to continue.");
        setCorrectCount((prev) => prev + 1);

        const testBtn = document.getElementById("testBtn");
        if (testBtn) {
          testBtn.disabled = false;
          testBtn.textContent = "Next Question";
        }
      } else {
        setSpeak(true);
        setText("That's not correct. Try again!");
        // Don't stop the timer or recognition - let them try again
      }
      setAntButton(false);
      setSynButton(false);
    };

    recognition.onend = () => {
      console.log("Speech recognition ended");
      setIsRecognitionActive(false);

      // Only restart if we're still within the time limit and no correct answer
      if (timerActive && timeLeft > 0 && !correctAnswer) {
        recognition.start();
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      if (event.error !== "no-speech") {
        stopTimer();
        setIsRecognitionActive(false);
        setSpeak(true);
        setText("There was an error with the microphone. Please try again.");

        const testBtn = document.getElementById("testBtn");
        if (testBtn) {
          testBtn.disabled = false;
          testBtn.textContent = "Try Again";
        }
      }
    };

    try {
      recognition.start();
    } catch (error) {
      console.error("Failed to start speech recognition:", error);
    }
  }
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopTimer();
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsRecognitionActive(false);
    };
  }, []);
  return (
    <div className="full">
      <div id="right-section" style={STYLES.area}>
        {/*<textarea rows={4} type="text" style={STYLES.text} value={text} onChange={(e) => setText(e.target.value.substring(0, 200))} />
        <button onClick={() => setSpeak(true)} style={STYLES.speak}> { speak? 'Running...': 'Speak' }</button>*/}
        <div className="button-sections">
          <button id="evone" onClick={handleClick} style={STYLES.speak}>
            {" "}
            {speak ? "Running..." : "Enable vOne"}
          </button>
          &nbsp;&nbsp;
          <button
            id="testBtn"
            onClick={handleMikeListening}
            style={STYLES.speak}
          >
            {" "}
            {listen ? "vOne Processing..." : "Lets Play Word Game"}
          </button>
        </div>
        {/* Timer Display */}
        {timerActive && (
          <div className="bg-gray-800 text-white p-4 rounded-lg">
            <p className="text-2xl timer font-bold">
              Time Left: <span id="tcount">{timeLeft} Secs</span>
            </p>
            {isRecognitionActive && (
              <p className="text-sm mt-2">Listening...</p>
            )}
          </div>
        )}
        <div id="user-vone-inputs">
          <div id="uinput">You spoken: {userSpokenWord}</div>
          <div id="canswer">Correct Answer is: {correctAnswer}</div>
        </div>
        <div id="user-word-inputs">
          <button id="antonyms" disabled={antButton} onClick={handleAntonym}>
            Antonyms
          </button>
          &nbsp;&nbsp;
          <button id="synonyms" disabled={synButton} onClick={handleSynonym}>
            Synonyms
          </button>
        </div>
        <div
          id="show_ant"
          className={`mt-4 p-4 bg-gray-100 rounded ${
            showAntonym ? "block" : "hidden"
          }`}
        >
          {antonym}
        </div>
        <div
          id="show_syn"
          className={`mt-4 p-4 bg-gray-100 rounded ${
            showSynonym ? "block" : "hidden"
          }`}
        >
          {synonym}
        </div>
        <div id="user-score">
          <div id="rightcount">Correct: {correctCount}</div>
          <div id="wrongcount">Wrong: {wrongCount}</div>
        </div>
      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
      />

      {/* <Stats /> */}
      <Canvas
        dpr={2}
        onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}
      >
        <OrthographicCamera makeDefault zoom={1000} position={[0.4, 1.55, 1]} />

        {/* <OrbitControls
        target={[0, 1.65, 0]}
      /> */}

        <Suspense fallback={null}>
          <Environment
            background={false}
            files="/images/photo_studio_loft_hall_1k.hdr"
          />
        </Suspense>

        <Suspense fallback={null}>
          <Bg />
        </Suspense>

        <Suspense fallback={null}>
          <Avatar
            avatar_url="/model15.glb"
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setAudioSource={setAudioSource}
            playing={playing}
          />
        </Suspense>
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  );
}

function Bg() {
  const texture = useTexture("/images/bg1.webp");

  return (
    <mesh position={[0.3, 1.6, -2]} scale={[0.4, 0.4, 0.4]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}

export default App;
